import * as Yup from "yup";
import countryData from "../../Data/CountryCode.json";

const getPhoneLength = (countryCode: string) => {
  const countryCodeWithoutPlus = countryCode?.replace(/^\+/, ""); // Remove the first '+' sign
  const country = countryData.find(
    (country) => country.phone === countryCodeWithoutPlus,
  );
  return country
    ? Array.isArray(country.phoneLength)
      ? country.phoneLength[0]
      : country.phoneLength
    : 0;
};

export const ProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long")
    .max(50, "Name must not exceed 50 characters")
    .matches(/^(?!.* {2,})/, "Double spaces are not allowed"),
  username: Yup.string().email("Invalid email").required("Email is required"),
  phone_number: Yup.string()
    .matches(/^[0-9]+$/, "Only numbers are allowed") // Ensure only numbers are allowed
    .matches(/^(?!.* {1,})/, "Spaces are not allowed") // Ensure no spaces are allowed
    .test("phone-length", "Invalid phone number length", function (value) {
      const countryCode = this.parent.country_code;
      const phoneLength = getPhoneLength(countryCode);
      if (phoneLength && phoneLength > 0) {
        return value ? value.toString().trim().length === phoneLength : true;
      }
      return true;
    })
    .required("Contact is required"),
  password: Yup.string()
    .matches(/^(?!.* {2,})/, "Password cannot contain double spaces")
    .matches(
      /(?=.*[a-z])/,
      "Password must contain at least one lowercase letter",
    )
    .matches(
      /(?=.*[A-Z])/,
      "Password must contain at least one uppercase letter",
    )
    .matches(/(?=.*\d)/, "Password must contain at least one number")
    .matches(
      /(?=.*[!@#$%^&*()_+|~<>?/-])/,
      "Password must contain at least one special character",
    )
    .matches(
      /^[A-Za-z\d!@#$%^&*()_+|~<>?/-]{8,}$/,
      "Password must be at least 8 characters long",
    )
    .required("Password is required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

export const DriverProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long")
    .max(50, "Name must not exceed 50 characters")
    .matches(/^(?!.* {2,})/, "Double spaces are not allowed"),
  username: Yup.string().email("Invalid email").required("Email is required"),
  assignedTo: Yup.string().required("Assigned To is required"),
  phone_number: Yup.string()
    .matches(/^[0-9]+$/, "Only numbers are allowed") // Ensure only numbers are allowed
    .matches(/^(?!.* {1,})/, "Spaces are not allowed") // Ensure no spaces are allowed
    .test("phone-length", "Invalid phone number length", function (value) {
      const countryCode = this.parent.country_code;
      const phoneLength = getPhoneLength(countryCode);
      if (phoneLength && phoneLength > 0) {
        return value ? value.toString().trim().length === phoneLength : true;
      }
      return true;
    })
    .required("Contact is required"),
  password: Yup.string()
    .matches(/^(?!.* {2,})/, "Double spaces are not allowed")
    .required("Password is required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});
