import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { FormProps } from "./interface";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import * as Yup from "yup";
import {
  compressImageWithCanvas,
  generateUUID,
} from "../../Common Functions/Function";
import { getImage } from "../../Api/Product/mutations";
import ImageLoader from "../../Effects/ImageLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PlacesAutocomplete from "react-places-autocomplete";
const Form = React.forwardRef<HTMLFormElement, FormProps>((props, ref) => {
  const { formconfig, onSubmit, inputfield } = props;
  const [formValues, setFormValues] = useState<any>(inputfield);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setisLoading] = useState(false);

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };
  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      const inputValue: string = (
        event.target as HTMLInputElement
      ).checked.toString();
      setFormValues((prevState: any) => ({
        ...prevState,
        [name]: inputValue,
      }));
    } else {
      setFormValues((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleEditorChange = (value: string) => {
    setFormValues((prevValues: any) => ({ ...prevValues, description: value }));
  };

  //validation code
  const [errors, setErrors] = useState<any>({});
  const validationSchema = Yup.object().shape(
    Object.fromEntries(
      formconfig?.flatMap((item, index) =>
        item.sub_header.map((field: any, subIndex) => [
          field.name,
          field.required &&
            Yup.string()
              .trim()
              .required(`${field.label} is required`)
              .when([], {
                is: () => field.min !== undefined,
                then: (schema) =>
                  schema.min(
                    field.min,
                    `${field.label} must be at least ${field.min} characters`,
                  ),
              })
              .when([], {
                is: () => field.max !== undefined,
                then: (schema) =>
                  schema.max(
                    field.max,
                    `${field.label} must be at most ${field.max} characters`,
                  ),
              }),
        ]),
      ),
    ),
  );

  const validateForm = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error: any) {
      // Specify the type of error as Yup.ValidationError
      const validationErrors = {};
      error.inner.forEach((err: any) => {
        //@ts-expect-error
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  //validation code

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    const isValid = await validateForm();
    if (isValid && onSubmit) onSubmit(formValues);
  };

  const handleFileInputChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files, name } = event.target;
    if (files && files.length > 0) {
      const validTypes = ["image/png", "image/jpg", "image/jpeg"];
      const fileSizeMB = files?.[0]?.size / 1024 / 1024; // Convert file size to MB

      if (!validTypes.includes(files[0].type)) {
        setisLoading(false);
        toast.error("Only PNG, JPG, and JPEG files are accepted.");
        return;
      }
      if (fileSizeMB > 30) {
        setisLoading(false);
        toast.error("File size exceeds 30 MB. Please upload a smaller file.");
        return;
      }
      setisLoading(true);

      const compressedFile: any = await compressImageWithCanvas(files[0]); // Set quality to 50%

      const key = `${generateUUID()}${compressedFile.name}`;
      let imageObject = {
        ContentType: compressedFile.type,
        Expires: "3000",
        Key: key,
        Bucket: process.env.REACT_APP_MEDIA_FILES,
      };
      const formData = new FormData();
      formData.append("file", compressedFile);

      try {
        const res = await getImage(imageObject);
        const imgpresignurl = JSON.parse(res.body);
        const response = await fetch(imgpresignurl.uploadURL, {
          method: "PUT",
          body: compressedFile,
          headers: {
            "Content-Type": compressedFile.type,
          },
        });

        if (response.ok) {
          setFormValues({
            ...formValues,
            [name]: `https://s3.af-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_FILES}/${key}`,
          });

          setisLoading(false);
        } else {
          console.error("Failed to upload image");
          setisLoading(false);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        setisLoading(false);
      } finally {
        setisLoading(false);
      }
    }
  };

  return (
    <div className="container">
      {isLoading && <ImageLoader />}
      <ToastContainer />
      <form onSubmit={handleSubmit} ref={ref}>
        {formconfig?.map((item, index) => (
          <div
            className="border border-grey rounded p-4 mt-4 white_bg"
            key={index}
          >
            <h4 className="theme_color fw-bold align-self-center">
              {item.heading}
            </h4>
            <div className="row">
              {item.sub_header.map((val: any, subIndex: any) => {
                const {
                  label,
                  fieldtype,
                  size,
                  type,
                  name,
                  options,
                  required,
                  readonly,
                  info,
                } = val;

                switch (fieldtype) {
                  case "input":
                    return (
                      <div className={`form-group ${size} mb-3`} key={subIndex}>
                        <label
                          htmlFor={`input-${index}-${subIndex}`}
                          className="form-label"
                        >
                          {label}
                          {required && <span className="text-danger">*</span>}
                        </label>
                        <input
                          type={type}
                          className="form-control"
                          name={name}
                          id={`input-${index}-${subIndex}`}
                          onChange={handleInputChange}
                          value={formValues[name] ?? ""}
                          readOnly={readonly}
                        />
                        {info && (
                          <small className="form-text text-muted">
                            <i className="bi bi-info-circle"></i>{" "}
                            {(() => {
                              const placeholder = "0";
                              return info.includes(placeholder)
                                ? info.replace(
                                    placeholder,
                                    formValues[name] ?? "",
                                  )
                                : info;
                            })()}
                          </small>
                        )}
                        {typeof name !== "undefined" && errors[name] && (
                          <div className="text-danger">{errors[name]}</div>
                        )}
                      </div>
                    );
                  case "textarea":
                    return (
                      <div className={`form-group ${size} mb-3`} key={subIndex}>
                        <label
                          htmlFor={`textarea-${index}-${subIndex}`}
                          className="form-label"
                        >
                          {label}
                          {required && <span className="text-danger">*</span>}
                        </label>
                        <textarea
                          id={`textarea-${index}-${subIndex}`}
                          className="form-control"
                          rows={3}
                          name={name}
                          onChange={handleInputChange}
                          value={formValues[name] ?? ""}
                        ></textarea>
                        {typeof name !== "undefined" && errors[name] && (
                          <div className="text-danger">{errors[name]}</div>
                        )}
                      </div>
                    );
                  case "checkbox":
                    return (
                      <div className={`form-group ${size} mb-3`} key={subIndex}>
                        <div className="d-flex ">
                          <input
                            type={type}
                            name={name}
                            onChange={handleInputChange}
                            checked={formValues[name] ?? false}
                            className="form-check-input p-0 m-0 me-2"
                          />
                          <label
                            htmlFor={`input-${index}-${subIndex}`}
                            className="form-label p-0 m-0"
                          >
                            {label}
                            {required && <span className="text-danger">*</span>}
                          </label>
                        </div>
                        {typeof name !== "undefined" && errors[name] && (
                          <div className="text-danger">{errors[name]}</div>
                        )}
                      </div>
                    );
                  case "file":
                    return (
                      <div
                        className={`form-group ${size} mb-3 `}
                        key={subIndex}
                      >
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Media
                          {required && <span className="text-danger">*</span>}
                        </label>
                        <div
                          className={`form-group ${size} w-100 mb-3 d-flex gap-2`}
                          key={subIndex}
                        >
                          <div className="new_product_media_outer p-3 p-md-4 flex-grow-1">
                            <div className="new_product_media_inner">
                              <div className="new_product_media_content  align-items-center justify-content-between">
                                <div>
                                  <i className="bi bi-arrow-up-circle upload_icon"></i>
                                </div>
                                <div>
                                  <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept="image/*,audio/*,video/*"
                                    style={{ display: "none" }}
                                    name={name}
                                    onChange={handleFileInputChangeAndUpload}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-outline-success"
                                    onClick={handleButtonClick}
                                  >
                                    Add Image
                                  </button>
                                </div>
                                <div>
                                  <span className="upload_img_text">
                                    Click to upload an image
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {formValues[name] && (
                            <div className="border border-grey p-3 rounded  white_bg flex-grow-1">
                              <h4 className="theme_color fw-bold mb-3 align-self-center">
                                {"Preview Image"}
                              </h4>
                              <div className="new_category_banner_inner d-block">
                                <div className="new_category_banner_content h-100 p-2">
                                  <div className="image-container-preview">
                                    <img
                                      src={formValues[name]}
                                      alt="img"
                                      className="image-preview"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {typeof name !== "undefined" && errors[name] && (
                            <div className="text-danger">{errors[name]}</div>
                          )}
                        </div>
                      </div>
                    );
                  case "select":
                    return (
                      <div className={`form-group ${size} mb-3`} key={subIndex}>
                        <label className="form-label">
                          {label}
                          {required && <span className="text-danger">*</span>}
                        </label>
                        <select
                          name={name}
                          className="form-select"
                          aria-label="Default select example"
                          onChange={handleInputChange}
                          value={formValues[name] ?? ""}
                        >
                          <option>Select...</option>
                          {options?.map((option: any, key: any) => (
                            <option key={key} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {typeof name !== "undefined" && errors[name] && (
                          <div className="text-danger">{errors[name]}</div>
                        )}
                      </div>
                    );
                  case "editor":
                    return (
                      <div className={`form-group ${size} mb-3`} key={subIndex}>
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          {label}
                          {required && <span className="text-danger">*</span>}
                        </label>
                        <ReactQuill
                          theme="snow"
                          value={formValues.description || ""}
                          onChange={handleEditorChange}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6] }],
                              ["bold", "italic", "link"],
                              [{ list: "ordered" }],
                              ["blockquote"],
                            ],
                          }}
                        />
                        {typeof name !== "undefined" && errors[name] && (
                          <div className="text-danger">{errors[name]}</div>
                        )}
                      </div>
                    );
                  case "location":
                    return (
                      <div>
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          {label}
                          {required && <span className="text-danger">*</span>}
                        </label>
                        <PlacesAutocomplete
                          value={formValues[name] ?? ""}
                          onChange={(address) => {
                            setFormValues((prevValues: any) => ({
                              ...prevValues,
                              location: address,
                            }));
                          }}
                          onSelect={async (address: string) => {
                            try {
                              // Geocode address
                              const results = await geocodeByAddress(address);
                              const { lat, lng } = await getLatLng(results[0]);

                              setFormValues((prevState: any) => ({
                                ...prevState,
                                location: address,
                                lat,
                                long: lng,
                              }));
                            } catch (error) {
                              console.error(
                                "Error fetching place details:",
                                error,
                              );
                            }
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                          }: any) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: "Search Places...",
                                  className:
                                    "location-search-input form-select",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {suggestions.map((suggestion: any) => (
                                  <div
                                    {...getSuggestionItemProps(suggestion)}
                                    key={suggestion.placeId}
                                  >
                                    {suggestion.description}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>

                        <pre
                          style={{
                            textAlign: "left",
                            padding: 5,
                          }}
                        ></pre>
                        {typeof name !== "undefined" && errors[name] && (
                          <div className="text-danger">{errors[name]}</div>
                        )}
                      </div>
                    );

                  case "status":
                    return (
                      <div className={`form-group ${size} mb-3`} key={subIndex}>
                        <div className="border border-grey p-3 rounded mt-4 white_bg">
                          <h4 className="theme_color fw-bold mb-3 align-self-center">
                            STATUS
                          </h4>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={name}
                              id="flexRadioDefault1"
                              value="disabled"
                              checked={!formValues[name]} // Map boolean to checked property
                              onChange={() => {
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  [name]: false, // Update state to false for "Disabled"
                                }));
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              Disabled
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={name}
                              id="flexRadioDefault2"
                              value="enabled"
                              checked={!!formValues[name]} // Map boolean to checked property
                              onChange={() => {
                                setFormValues((prev: any) => ({
                                  ...prev,
                                  [name]: true, // Update state to true for "Enabled"
                                }));
                              }}
                            />
                            <label
                              className="form-check-label theme_color"
                              htmlFor="flexRadioDefault2"
                            >
                              Enabled
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        ))}
      </form>
    </div>
  );
});

export default Form;
