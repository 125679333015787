import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Routers } from "./Routes/Routes";
import Communications from "./Component/Communication/Communications";
import NewCommunication from "./Component/Communication/NewCommunication";
import Categories from "./Component/Categories/Categories";
import NewSubCategories from "./Component/Categories/SubCategory/NewSubCategories";
import Store from "./Component/Store/Store";
import NewStore from "./Component/Store/NewStore";
import Products from "./Component/Products/Products";
import Customer from "./Component/Customer/Customer";
import Recipes from "./Component/Recipes/Recipes";
import NewRecipes from "./Component/Recipes/NewRecipes";
import Driver from "./Component/Driver/Driver";
import InStore from "./Component/InStore/InStore";
import Dashboard from "./Component/Dashboard/Dashboard";
import Login from "./Component/Login/Login";
import ForgotPassword from "./Component/ForgotPassword/ForgotPassword";
import LayoutHandle from "./Component/Layout/LayoutHandle";
import CustomerDetails from "./Component/Customer/CustomerDetails";
import MenuManager from "./Component/Menu Manager/MenuManager";
import Overview from "./Component/Menu Manager/Tabs/Overview/Overview";
import Customizations from "./Component/Menu Manager/Tabs/Customizations/Customizations";
import CategoriesHandler from "./Component/Menu Manager/Tabs/Categories/Main";
import MenuHandler from "./Component/Menu Manager/Tabs/Menu/Main";
import DriverSignUp from "./Component/Login/DriverSingUp";
import ChefSingUp from "./Component/Login/ChefSingUp";
import ShoperSingUp from "./Component/Login/ShoperSingUp";
import NewProducts from "./Component/Products/NewProducts";
import UpdateRecipes from "./Component/Recipes/UpdateRecipes";
import UpdateStore from "./Component/Store/UpdateStore";
import OrdersDetails from "./Component/Orders/OrdersDetails";
import Orders from "./Component/Orders/Orders";
import NewCategory from "./Component/Categories/ParentCategory/NewCategory";
import UpdateSubCategories from "./Component/Categories/SubCategory/UpdateSubCategories";
import UpdateCategory from "./Component/Categories/ParentCategory/UpdateCategory";
import Profile from "./Component/Profile/Profile";
import RoasterDriver from "./Component/RoasterDriver/RoasterDriver";
import TakeAwayProducts from "./Component/TakeAwayProducts/Products";
import TakeAwayNewProduct from "./Component/TakeAwayProducts/NewProducts";
import MainItems from "./Component/Menu Manager/Tabs/Items/Main";
import "./style.css";
import "./fonts/fonts.css";
import "./custom.scss";
import "./responsive.scss";
import UpdateDriver from "./Component/Driver/UpdateDriver";
import UpdateChef from "./Component/InStore/UpdateChef";
import UpdateShopper from "./Component/InStore/UpdateShopper";
import Manager from "./Component/StoreManager/Manager";
import UpdateManager from "./Component/StoreManager/UpdateManager";
import ManagerSignUp from "./Component/StoreManager/ManagerSingUp";
import DriverDetails from "./Component/Driver/DriverDetails";
import SalesReports from "./Component/Reports/SalesReports";
import Coupons from "./Component/NewCoupon/Coupons";
import NewCoupon from "./Component/NewCoupon/NewCoupon";
import UpdateCoupon from "./Component/NewCoupon/UpdateCoupon";
import MeberShip from "./Component/MeberShip/MeberShip";
import NewMemberShip from "./Component/MeberShip/NewMemberShip";
import UpdateMemberShip from "./Component/MeberShip/updateMemberShip";
import ExtraTakeAwayProducts from "./Component/ExtraTakeAwayProducts/Products";
import AllProducts from "./Component/AllProducts/AllProducts";
import UpdateProductManipulation from "./Component/AllProducts/UpdateProduct";
import PolygonDraw from "./Component/Polygon/Polygon";

const Main = () => {
  return (
    <Router>
      <Routes>
        <Route path={Routers?.login} element={<Login />}></Route>
        <Route
          path={Routers?.forgotpassword}
          element={<ForgotPassword />}
        ></Route>
      </Routes>
      <LayoutHandle>
        <Routes>
          <Route path={Routers?.dashboard} element={<Dashboard />}></Route>
          <Route
            path={Routers?.communication}
            element={<Communications />}
          ></Route>
          <Route
            path={Routers?.newcommunications}
            element={<NewCommunication />}
          ></Route>
          <Route path={Routers?.coupons} element={<Coupons />}></Route>
          <Route path={Routers?.newcoupon} element={<NewCoupon />}></Route>
          <Route
            path={Routers?.updateCoupons}
            element={<UpdateCoupon />}
          ></Route>
          <Route path={Routers?.categories} element={<Categories />}></Route>
          <Route
            path={Routers?.newcategories}
            element={<NewCategory />}
          ></Route>
          <Route
            path={Routers?.updatecategories}
            element={<UpdateCategory />}
          ></Route>
          <Route
            path={Routers?.updateSubCategories}
            element={<UpdateSubCategories />}
          ></Route>
          <Route
            path={Routers?.newSubcategories}
            element={<NewSubCategories />}
          ></Route>
          <Route path={Routers?.store} element={<Store />}></Route>
          <Route path={Routers?.newstore} element={<NewStore />}></Route>
          <Route path={Routers?.updateStore} element={<UpdateStore />}></Route>
          <Route path={Routers?.products} element={<Products />}></Route>
          <Route
            path={Routers?.updateproduct}
            element={<UpdateProductManipulation />}
          ></Route>
          <Route path={Routers?.newproduct} element={<NewProducts />}></Route>
          <Route path={Routers?.customer} element={<Customer />}></Route>
          <Route
            path={Routers?.customerdetails}
            element={<CustomerDetails />}
          ></Route>
          <Route path={Routers?.recipes} element={<Recipes />}></Route>
          <Route
            path={Routers?.updaterecipes}
            element={<UpdateRecipes />}
          ></Route>
          <Route path={Routers?.newrecipes} element={<NewRecipes />}></Route>
          <Route path={Routers?.drivers} element={<Driver />}></Route>
          <Route
            path={Routers?.driverdetails}
            element={<DriverDetails />}
          ></Route>
          <Route
            path={Routers?.updatedrivers}
            element={<UpdateDriver />}
          ></Route>
          <Route path={Routers?.manager} element={<Manager />}></Route>
          <Route
            path={Routers?.updatemanager}
            element={<UpdateManager />}
          ></Route>{" "}
          <Route
            path={Routers?.createmanager}
            element={<ManagerSignUp />}
          ></Route>
          <Route path={Routers?.updateChef} element={<UpdateChef />}></Route>
          <Route
            path={Routers?.updateShopper}
            element={<UpdateShopper />}
          ></Route>
          <Route path={Routers?.instore} element={<InStore />}></Route>
          <Route path={Routers?.orders} element={<Orders />}></Route>
          <Route
            path={Routers?.ordersDetails}
            element={<OrdersDetails />}
          ></Route>
          <Route path={Routers?.menumanager} element={<MenuManager />}>
            <Route path={Routers?.overview} element={<Overview />}></Route>
            <Route path={Routers?.menu} element={<MenuHandler />}></Route>
            <Route
              path={Routers?.menucategories}
              element={<CategoriesHandler />}
            ></Route>
            <Route path={Routers?.items} element={<MainItems />}></Route>
            <Route
              path={Routers?.customizations}
              element={<Customizations />}
            ></Route>
          </Route>
          <Route
            path={Routers?.driversignup}
            element={<DriverSignUp />}
          ></Route>
          <Route path={Routers?.chefsignup} element={<ChefSingUp />}></Route>
          <Route
            path={Routers?.shopersignup}
            element={<ShoperSingUp />}
          ></Route>
          <Route path={Routers?.profile} element={<Profile />}></Route>
          <Route
            path={Routers?.roasterdriver}
            element={<RoasterDriver />}
          ></Route>
          <Route
            path={Routers?.takeawayproducts}
            element={<TakeAwayProducts />}
          ></Route>
          <Route
            path={Routers?.takeawaynewproduct}
            element={<TakeAwayNewProduct />}
          ></Route>
          <Route
            path={Routers?.takeawayupdateproduct}
            element={<UpdateProductManipulation />}
          ></Route>
          <Route
            path={Routers?.salesReports}
            element={<SalesReports />}
          ></Route>
          <Route path={Routers?.memberShip} element={<MeberShip />}></Route>
          <Route
            path={Routers?.NewMemberShip}
            element={<NewMemberShip />}
          ></Route>
          <Route
            path={Routers?.updateMemberships}
            element={<UpdateMemberShip />}
          ></Route>
          <Route
            path={Routers?.extratakeawayproducts}
            element={<ExtraTakeAwayProducts />}
          ></Route>
          <Route
            path={Routers?.extratakeawayupdateproduct}
            element={<UpdateProductManipulation />}
          ></Route>
          <Route path={Routers?.allproducts} element={<AllProducts />}></Route>
          <Route
            path={Routers?.updateProductsManipulation}
            element={<UpdateProductManipulation />}
          ></Route>
          <Route path={Routers?.polygon} element={<PolygonDraw />}></Route>
          <Route
            path="*"
            element={<Navigate to={Routers?.dashboard} replace />}
          />
        </Routes>
      </LayoutHandle>
    </Router>
  );
};

export default Main;
