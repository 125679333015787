import { FormConfigItem } from "../../Blocks/Form/interface";

const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "name",
    label: "Store Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "name",
    },
  },
  {
    field: "storeCode",
    label: "Store Code",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Code",
    },
  },
  {
    field: "location",
    label: "Store Location",
    type: "text",
    // sub_header: {
    //   inputtype: "input",
    //   placeholder: "Store Location",
    // },
  },
  {
    field: "itemStatus",
    label: "Status",
    type: "icon",
    sub_header: {
      inputtype: "select",
    },
  },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

const data = [
  {
    id: "1",
    code: "Item",
    store_location: "Store Location 100",
    status: "true",
  },
  {
    id: "2",
    code: "Item",
    store_location: "Store Location 100",
    status: "true",
  },
  {
    id: "3",
    code: "Item",
    store_location: "Store Location 100",
    status: "true",
  },
];

const formconfig: FormConfigItem[] = [
  {
    heading: "General",
    sub_header: [
      {
        label: "Store Code",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "storeCode",
        required: true,
        readonly: true,
      },
      {
        label: "Store Title",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "name",
        required: true,
        min: 1,
        max: 50,
      },
      {
        label: "Store Description",
        fieldtype: "textarea",
        type: "textarea",
        size: "col-12",
        name: "description",
        required: false,
      },
      // {
      //   label: "Store Manager",
      //   fieldtype: "input",
      //   type: "text",
      //   size: "col-12",
      //   name: "storeManager",
      //   required: true,
      //   min: 3,
      //   max: 50,
      // },
      {
        label: "Store Location",
        fieldtype: "location",
        type: "text",
        size: "col-12",
        name: "location",
        required: true,
      },

      {
        label: "Latitude",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "lat",
        required: true,
        readonly: true,
      },
      {
        label: "Longitude",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "long",
        required: true,
        readonly: true,
      },
      {
        label: "Delivery Cost",
        fieldtype: "input",
        type: "text",
        size: "col-12",
        name: "deliveryCost",
        required: true,
      },
      {
        label: "Store Order Number Prefix",
        fieldtype: "input",
        type: "text",
        size: "col-12",
        name: "storeOrderNumberPrefix",
        required: false,
      },
      {
        label: "Store Max Cart Items Allowed",
        fieldtype: "input",
        type: "number",
        size: "col-6",
        name: "storeMaxCartItemsAllowed",
        required: false,
      },
      {
        label: "Store Max Driver Tip Amount",
        fieldtype: "input",
        type: "number",
        size: "col-6",
        name: "storeMaxDriverTipAmount",
        required: false,
      },
      {
        label: "Store Max Product Quantity",
        fieldtype: "input",
        type: "number",
        size: "col-6",
        name: "storeMaxProductQuantity",
        required: false,
      },
      {
        label: "Store Min Cart Items Allowed",
        fieldtype: "input",
        type: "number",
        size: "col-6",
        name: "storeMinCartItemsAllowed",
        required: false,
      },
      {
        label: "Store Min Driver Tip Amount",
        fieldtype: "input",
        type: "number",
        size: "col-6",
        name: "storeMinDriverTipAmount",
        required: false,
      },
      {
        label: "Store Min Product Quantity",
        fieldtype: "input",
        type: "number",
        size: "col-6",
        name: "storeMinProductQuantity",
        required: false,
      },
      {
        label: "Store Year Based Order Format",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "storeYearBasedOrderFormat",
        required: false,
      },
      {
        label: "Store Meal Based Order Format",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "storeMealBasedOrderFormat",
        required: false,
      },
      {
        label: "Store Based Order Format",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "storeStoreBasedOrderFormat",
        required: false,
      },
      {
        label: "Store Both Order Format",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "storeBothOrderFormat",
        required: false,
      },
      {
        label: "File",
        fieldtype: "file",
        type: "file",
        size: "col-6",
        name: "imageURL",
        required: false,
      },
      {
        label: "Status",
        fieldtype: "status",
        type: "text",
        size: "col-6",
        name: "itemStatus",
        required: false,
      },
    ],
  },
];

const inputfield: { [key: string]: string | boolean } = {
  PK: "",
  SK: "",
  name: "",
  storeCode: "",
  description: "",
  location: "",
  lat: "",
  long: "",
  deliveryCost: "",
  storeManager: "",
  storeOrderNumberPrefix: "",
  storeMaxCartItemsAllowed: "",
  storeMaxDriverTipAmount: "",
  storeMaxProductQuantity: "",
  storeMinCartItemsAllowed: "",
  storeMinDriverTipAmount: "",
  storeMinProductQuantity: "",
  storeYearBasedOrderFormat: "",
  storeMealBasedOrderFormat: "",
  storeStoreBasedOrderFormat: "",
  storeBothOrderFormat: "",
};

export { columns_name, data, formconfig, inputfield };
