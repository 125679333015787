const Routers = {
  dashboard: "/",

  profile: "/profile",

  coupons: "/coupons",
  updateCoupons: "/updateCoupons/:PK/:SK/",
  newcoupon: "/coupons/newcoupon",

  communication: "/communications",
  newcommunications: "/communications/newcommunications",

  categories: "/categories",
  newcategories: "/categories/newcategories",
  updatecategories: "/categories/update/:PK/:SK/",
  newSubcategories: "/categories/subcategories",
  updateSubCategories: "/categories/sub-update/:PK/:SK/",

  store: "/store",
  newstore: "/store/newstore",
  updateStore: "/store/update/:PK/:SK/",

  warehouse: "/warehouse",
  newwarehouse: "/warehouse/newWarehouse",
  updatewarehouse: "/warehouse/update/:PK/:SK/",

  products: "/products",
  newproduct: "/products/newproduct",
  updateproduct: "/products/update/:PK/:SK/:productType",

  takeawayproducts: "/takeawayproducts",
  takeawaynewproduct: "/takeawayproducts/newproduct",
  takeawayupdateproduct: "/takeawayproducts/update/:PK/:SK/:productType",

  extratakeawayproducts: "/extratakeawayproducts",
  extratakeawayupdateproduct:
    "/extratakeawayproducts/update/:PK/:SK/:productType",

  customer: "/customers",
  customerdetails: "/customerdetails/:userId/",

  recipes: "/recipes",
  newrecipes: "/recipes/newrecipes",
  updaterecipes: "/recipes/updaterecipes/:PK/:SK/",

  drivers: "/drivers",
  updatedrivers: "/drivers/updatedrivers/:PK/:SK/",
  driverdetails: "/drivers/driverdetails/:userId/",

  manager: "/manager",
  createmanager: "/manager/createmanager/",
  updatemanager: "/manager/updatemanager/:PK/:SK/",

  instore: "/instore",
  updateChef: "/instore/updateChef/:PK/:SK/",
  updateShopper: "/instore/updateShopper/:PK/:SK/",

  orders: "/orders",
  ordersDetails: "/orders/ordersDetails/:IdUser/:orderId",

  login: "/login",
  driversignup: "/drivers/driversignup",
  shopersignup: "/instore/newshopersignup",
  chefsignup: "/instore//chefsignup",
  forgotpassword: "/forgotpassword",

  menumanager: "/menu-manager",
  overview: "/menu-manager/overview",
  menu: "/menu-manager/menu",
  menucategories: "/menu-manager/menu-categories",
  newmenucategories: "/menu-manager/menu-categories/new",
  items: "/menu-manager/items",
  customizations: "/menu-manager/customizations",

  roasterdriver: "/roasterdriver",

  salesReports: "/salesReports",

  memberShip: "/memberShip",
  NewMemberShip: "/memberShip/NewMemberShip",
  updateMemberships: "/memberShip/updateMemberships/:PK/:SK/",

  allproducts: "/allproducts",

  updateProductsManipulation: "/allproducts/update/:PK/:SK/:productType",

  polygon: "/polygon",
};

export { Routers };
