import { Amplify, ResourcesConfig } from "aws-amplify";
import web from "./awsconfig";
import { Authenticator } from "@aws-amplify/ui-react";
import Main from "./main";
import { QueryClient, QueryClientProvider } from "react-query";
import { AlertProvider } from "./Component/ContextAPI/ContextApi";
import { StoreIdProvider } from "./Component/ContextAPI/StoreIdContext";
import { UserInfoProvider } from "./Component/ContextAPI/UserContext";
import { useEffect } from "react";
import { handleNoCurrentUserError } from "./Api/utils";
import { Routers } from "./Routes/Routes";

Amplify.configure(web as ResourcesConfig);

function App() {
  const queryClient = new QueryClient();

  const excludedPaths = [Routers?.login, Routers?.forgotpassword];

  useEffect(() => {
    const checkUserSession = async () => {
      const currentPath = window.location.pathname;
      if (!excludedPaths.includes(currentPath)) {
        await handleNoCurrentUserError();
      }
    };

    checkUserSession(); // eslint-disable-next-line
  }, []);

  return (
    <div className="w-100 d-flex flex-column min-vh-100">
      <QueryClientProvider client={queryClient}>
        <Authenticator.Provider>
          <AlertProvider>
            <StoreIdProvider>
              <UserInfoProvider>
                <Main />
              </UserInfoProvider>
            </StoreIdProvider>
          </AlertProvider>
        </Authenticator.Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
