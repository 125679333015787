import { Link, useNavigate } from "react-router-dom";
import Table from "../../Blocks/Table/Table";
import { columns_name } from "./data";
import { Routers } from "../../Routes/Routes";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  getStatus,
  handlePageSizes,
  handlePaginations,
  updateFilterAndPagination,
} from "../../Common Functions/Function";
import debounce from "lodash.debounce";
import { getAllCoupons, searchCoupon } from "../../Api/Search/Coupon/Coupons";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import {} from "react-toastify";
import { useAlert } from "../ContextAPI/ContextApi";
import Toast from "../Toast/Toast";
import { CouponResponse } from "./utils";
import { addCoupons } from "../../Api/Coupons/mutation";
import swal from "sweetalert";

const Coupons = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [total, setTotal] = useState();

  const [coupontotal, setcouponTotal] = useState(0);

  const navigate = useNavigate();
  const { alert, message } = useAlert();
  let index = process.env.REACT_APP_STACK
    ? `coupon${process.env.REACT_APP_STACK}`
    : "coupon";

  const { storeId } = useStoreId();
  type SearchData = {
    fields: string;
    value: string;
  };

  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50,
    total_pages: 0,
  });

  // eslint-disable-next-line
  const GetCouponlist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchCoupon("coupon", filtervalue, Pagination, storeId)
        .then(async (res) => {
          const result = await CouponResponse(res);
          setCoupons(result?.modifiedData);
          setTotal(result?.total);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }, 3000),
    [storeId],
  );

  useEffect(() => {
    GetCouponlist(filtervalue, Pagination);
  }, [GetCouponlist, filtervalue, Pagination]);

  const handleDelete = async (data: any) => {
    const willDelete = await swal({
      text: `Are you sure you want to ${getStatus(data?.isDeleted)} Coupon ${data?.couponCode}`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: {
          text: getStatus(data?.isDeleted),
          value: true,
        },
      },
    });

    if (willDelete) {
      const newData: any = {
        PK: data?.PK,
        SK: data?.SK,
        id: data?.id,
        discountType: JSON.stringify(data?.discountType),
        categories: JSON.stringify(data?.categories),
        products: JSON.stringify(data?.products),
        couponCode: data?.couponCode,
        description: data?.description,
        startDate: new Date(data?.startDate).toISOString(),
        endDate: new Date(data?.endDate).toISOString(),
        itemStatus: data?.itemStatus,
        extraField1: data?.extraField1,
        extraField2: data?.extraField2,
        extraField3: data?.extraField3,
        entityName: index,
        itemAction: "update",
        isDeleted: getStatus(data?.isDeleted) === "Disable" ? true : false,
      };
      addCoupons(newData)
        .then((response: any) => {
          const result = JSON.parse(response?.data?.createCoupon);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            swal(
              getStatus(data?.isDeleted),
              `Coupon has been ${getStatus(data?.isDeleted)} successfully`,
              "success",
            );
            GetCouponlist(filtervalue, Pagination);
          } else {
            swal(errorMessage);
          }
        })
        .catch((err) =>
          swal("Failed to disable coupon. Please try again later."),
        );
    }
  };

  const handleEdit = (data: {
    SK: string;
    PK: string;
    parent_category: string;
  }) => {
    navigate(
      `${Routers.updateCoupons
        .replace(":PK", encodeURIComponent(data.PK))
        .replace(":SK", encodeURIComponent(data?.SK))}`,
    );
  };

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  // eslint-disable-next-line
  const GetCouponCount = useCallback(
    debounce(async () => {
      setIsLoading(true);
      await getAllCoupons("", "coupon", storeId)
        .then(async (res) => {
          const result = await CouponResponse(res);
          setcouponTotal(result?.total);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 3000),
    [],
  );

  useEffect(() => {
    GetCouponCount(); // eslint-disable-next-line
  }, []);

  return (
    <div>
      {alert && message && <Toast type="success" message={message} />}
      <div className="row my-4 justify-content-between">
        <div className="col-auto ">
          <h3 className="theme_color fw-bolder mb-0">{"Coupons"}</h3>
        </div>
        {coupontotal === 0 && isLoading === false && (
          <div className="col-auto ms-auto">
            <Link className="text-decoration-none" to={Routers?.newcoupon}>
              <button className="btn btn-outline-success" type="button">
                {"New Coupons"}
              </button>
            </Link>
          </div>
        )}
      </div>

      <div className="border p-4 border-grey  rounded  white_bg">
        <Table
          columns={columns_name}
          data={coupons}
          paginationShow={true}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          total={total}
          isLoading={isLoading}
          filterdata={filterdata}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
        ></Table>{" "}
      </div>
    </div>
  );
};

export default Coupons;
