import { FormConfigItem } from "../../Blocks/Form/interface";

const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "imageURL",
    label: "Media file",
    type: "img",
  },
  {
    field: "itemName",
    label: "Product Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Product Name",
    },
  },
  // {
  //   field: "subcategoryId",
  //   label: "Sub Category",
  //   type: "text",
  //   sub_header: {
  //     inputtype: "",
  //     placeholder: "   ",
  //   },
  // },
  {
    field: "salesPrice",
    label: "Sales Price",
    type: "text",
    sub_header: {
      inputtype: "fromTo",
      from: {
        placeholder: "From",
      },
      to: {
        placeholder: "To",
      },
    },
  },
  {
    field: "costPrice",
    label: "Cost Price",
    type: "text",
    sub_header: {
      inputtype: "fromTo",
      from: {
        placeholder: "From",
      },
      to: {
        placeholder: "To",
      },
    },
  },
  {
    field: "sku",
    label: "Product Code",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Product Code",
    },
  },
  // {
  //   field: "qty",
  //   label: "Qty",
  //   type: "text",
  //   sub_header: {
  //     inputtype: "fromTo",
  //     from: {
  //       placeholder: "From",
  //     },
  //     to: {
  //       placeholder: "To",
  //     },
  //   },
  // },
  {
    field: "itemStatus",
    label: "ARCH Status",
    type: "icon",
    sub_header: {
      inputtype: "select",
    },
  },
  {
    field: "isDeleted",
    label: "Action",
    type: "Action",
    sub_header: {
      inputtype: "",
    },
  },
];

const formconfig: FormConfigItem[] = [
  {
    heading: "General",
    sub_header: [
      {
        label: "Name",
        fieldtype: "input",
        type: "text",
        size: "col-12",
        name: "name",
      },
      {
        label: "Product Code",
        fieldtype: "input",
        type: "text",
        size: "col-md-12",
        name: "sku",
      },
      // {
      //   label: "Price",
      //   fieldtype: "input",
      //   type: "number",
      //   size: "col-md-4",
      //   name: "price",
      // },
      // {
      //   label: "Weight",
      //   fieldtype: "input",
      //   type: "text",
      //   size: "col-md-4",
      //   name: "weight",
      // },
      {
        label: "Status",
        fieldtype: "select",
        type: "",
        size: "col-md-12",
        name: "status",
        options: [
          {
            value: "Active",
            label: "Active",
          },
          {
            value: "Deactive",
            label: "Deactive",
          },
        ],
      },
      {
        label: "Category",
        fieldtype: "select",
        type: "",
        size: "col-md-12",
        name: "category",
        options: [
          {
            value: "1",
            label: "Fruits",
          },
          {
            value: "2",
            label: "Vegetables",
          },
        ],
      },

      {
        label: "Description",
        fieldtype: "editor",
        type: "",
        size: "col-12",
        name: "description",
      },

      {
        label: "Product Brand",
        fieldtype: "input",
        type: "text",
        size: "col-12 col-md-6 col-lg-5 col-xl-4",
        name: "productBrand",
      },
      {
        label: "Product Size",
        fieldtype: "input",
        type: "text",
        size: "col-12 col-md-6 col-lg-5 col-xl-4",
        name: "productSize",
      },
      {
        label: "Cost Price",
        fieldtype: "input",
        type: "text",
        size: "col-12 col-md-6 col-lg-5 col-xl-4",
        name: "costPrice",
      },
      {
        label: "Sales Price",
        fieldtype: "input",
        type: "text",
        size: "col-12 col-md-6 col-lg-5 col-xl-4",
        name: "salesPrice",
      },
      {
        label: "Pack Weight Grams",
        fieldtype: "input",
        type: "text",
        size: "col-12 col-md-6 col-lg-5 col-xl-4",
        name: "packWeightGrams",
      },
      {
        label: "Pack Size in mm",
        fieldtype: "input",
        type: "text",
        size: "col-12 col-md-6 col-lg-5 col-xl-4",
        name: "packSizeInmm",
      },
      {
        label: "Local/International",
        fieldtype: "select",
        type: "select",
        size: "col-12 col-md-6 col-lg-5 col-xl-4",
        name: "localInternational",
        options: [
          {
            value: "1",
            label: "Local",
          },
          {
            value: "2",
            label: "International",
          },
        ],
      },
      {
        label: "Halaal/Kosher",
        fieldtype: "select",
        type: "select",
        size: "col-12 col-md-6 col-lg-5 col-xl-4",
        name: "halaalKosher",
        options: [
          {
            value: "1",
            label: "Halaal",
          },
          {
            value: "2",
            label: "Kosher",
          },
        ],
      },
      {
        label: "Vegitarian/Vegan",
        fieldtype: "select",
        type: "select",
        size: "col-12 col-md-6 col-lg-5 col-xl-4",
        name: "vegetarianVegan",
        options: [
          {
            value: "1",
            label: "Vegatarian",
          },
          {
            value: "2",
            label: "Vegan",
          },
        ],
      },

      {
        label: "Online Picture",
        fieldtype: "checkbox",
        type: "checkbox",
        size: "col-12 col-md-6 col-lg-4 col-xl-3",
        name: "onlinePicture",
      },
      {
        label: "Gluten Free",
        fieldtype: "checkbox",
        type: "checkbox",
        size: "col-12 col-md-6 col-lg-4 col-xl-3",
        name: "glutenFree",
      },
      {
        label: "Alcoholic",
        fieldtype: "checkbox",
        type: "checkbox",
        size: "col-12 col-md-6 col-lg-4 col-xl-3",
        name: "alcoholic",
      },
      {
        label: "Imported",
        fieldtype: "checkbox",
        type: "checkbox",
        size: "col-12 col-md-6 col-lg-4 col-xl-3",
        name: "imported",
      },
      {
        label: "Media",
        fieldtype: "file",
        type: "file",
        size: "col-6",
        name: "media",
      },
    ],
  },
];

const inputfield: { [key: string]: string | boolean } = {
  name: "",
  sku: "",
  categoryId: "",
  description: "",
  productBrand: "",
  productSize: "",
  // price: "",
  status: true,
  salesPrice: "",
  packWeightGrams: "",
  packSizeInmm: "",
  localInternational: "",
  halaalKosher: "",
  vegetarianVegan: "",
  onlinePicture: false,
  glutenFree: false,
  alcoholic: false,
  imported: false,
  imageURL: "",
  storeId: "",
  halaal: false,
  kosher: false,
  vegetarian: false,
  vegan: false,
  sugarFree: false,
};

const selectedKeys = [
  "archDescription",
  "archItemName",
  "productBrand",
  "productSize",
  "costPrice",
  "salesPrice",
  "packWeightGrams",
  "packSizeInmm",
  "localInternational",
  "halaalKosher",
  "vegetarianVegan",
  "onlinePicture",
  "glutenFree",
  "alcoholic",
  "imported",
  "packSizeBreadth",
  "packSizeHeight",
  "packSizeLength",
  "halaal",
  "kosher",
  "vegetarian",
  "vegan",
  "sugarFree",
  "archPckSizeBreadth",
  "archProductSize",
  "archCostPrice",
  "archProductBrand",
  "archPackSizeHeight",
  "archDepth",
  "archTaxRate",
  "archSalesPrice",
];

export { columns_name, formconfig, inputfield, selectedKeys };
