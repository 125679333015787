import { ReactNode, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Asset/logo_white.png";
import { ReactComponent as Logo } from "../../Asset/logo_dark.svg";
import { sidebar_menu } from "../Sidebar/data";
import { ReactComponent as Search } from "../../Asset/search.svg";
import { ReactComponent as Setting } from "../../Asset/settings.svg";
import { ReactComponent as Indent } from "../../Asset/indent-decrease.svg";
import { signOut } from "aws-amplify/auth";
import Spinner from "../../Effects/Spinner";
import { Routers } from "../../Routes/Routes";
import { useUserInfo } from "../ContextAPI/UserContext";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { getAllStore } from "../../Api/Search/Store/Store";
import { StoreResponse } from "../Store/utils";
import { matchesRestrictedUrl } from "./utilts";
import { isValidJSON } from "../../Common Functions/Function";
import { useAlert } from "../ContextAPI/ContextApi";
import { handleTimeout } from "../../Api/utils";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { userInfo } = useUserInfo();
  const user = userInfo ? JSON.parse(userInfo) : null;
  const [open, setOpen] = useState(true);
  const navgate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { setAlert } = useAlert();

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getWindowDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    if (windowDimensions?.width < 1199) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [windowDimensions]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const Logout = async () => {
    try {
      await signOut();
      localStorage.clear();
      window.location.href = "/login";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const loginTimeString = localStorage.getItem("login-time");

    const excludedPaths = [Routers?.login, Routers?.forgotpassword];
    const currentPath = window.location.pathname;

    if (loginTimeString) {
      const loginTime = parseInt(loginTimeString, 10);
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - loginTime;
      const twelveHoursInMs = 12 * 60 * 60 * 1000;

      if (elapsedTime > twelveHoursInMs) {
        if (!excludedPaths.includes(currentPath)) {
          handleTimeout();
        }
      }
    }
    if (user) {
      if (user?.sub) {
        setLoading(false);
        console.log("Logged In");
      }
    } else {
      if (
        location.pathname === "/login" ||
        location.pathname === "/forgotpassword"
      ) {
        navgate(location.pathname);
      } else {
        navgate("/login");
      }
      setLoading(false);
    }
  }, [navgate, location.pathname, user]);

  const Toggler = () => {
    return open
      ? " menu_icons cursor-pointer"
      : "bi bi-arrows-expand-vertical menu_icons m-0 cursor-pointer";
  };

  const path = location.pathname.split("/");
  const pathname = `/${path[1]}`;
  const {
    storeId,
    storeList,
    updateStoreList,
    updateStoreId,
    updateStoreName,
    refetchStoreList,
    updateLatitude,
    updateLongitude,
  } = useStoreId();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAllStore("", "store");
        const result = await StoreResponse(res);
        updateStoreList(result?.modifiedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // eslint-disable-next-line
  }, [refetchStoreList]);

  const navigate = useNavigate();
  const updateStore = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    const data = isValidJSON(value) ? JSON.parse(value) : value;
    const currentUrl = window.location.pathname;
    const { storeCode: storeId, name: storeName, lat, long } = data;
    console.log(data);
    updateStoreId(storeId ? storeId : "");
    updateStoreName(storeName);
    updateLatitude(lat);
    updateLongitude(long);
    if (matchesRestrictedUrl(currentUrl)) {
      const modifiedUrl = `/${currentUrl.split("/")[1]}`;
      navigate(modifiedUrl);
      return;
    }
  };

  if (loading) {
    return <Spinner />;
  }

  const findStoreById = () => {
    //@ts-expect-error
    const foundItem = storeList.find((item) => item?.storeCode === storeId);
    return foundItem;
  };

  return (
    <div className="d-flex">
      <div className={open ? "sidebar_open" : "sidebar_close"}>
        <div
          className={`d-flex w-100 sidebar-logo-wrapper justify-content-${
            open && "between"
          } `}
        >
          {open && <img src={logo} alt="logo" width={120} />}
          <div>
            {open ? (
              <Indent onClick={handleDrawer} className="cursor-pointer" />
            ) : (
              <i className={Toggler()} onClick={handleDrawer}></i>
            )}
          </div>
        </div>

        <div className="mt-4 sidebar_menu">
          <ul className="w-100">
            {sidebar_menu?.map((item, key) => (
              <>
                {open && (
                  <span key={key} className="menu_heading">
                    {item?.menu}
                  </span>
                )}
                {item?.submenu?.map((val) => (
                  <li
                    key={key}
                    className={`${pathname === val?.route && "active_tab"}`}
                    onClick={() => {
                      setAlert(false);
                    }}
                  >
                    <div className={"list-group-item gap-2 "}>
                      <Link
                        to={val?.route}
                        className={"d-flex gap-2 menu"}
                        onClick={() =>
                          windowDimensions?.width < 768 && handleDrawer()
                        }
                      >
                        <span>{val?.icon}</span>
                        <span>{open && val?.label}</span>
                      </Link>
                    </div>
                  </li>
                ))}
              </>
            ))}
            <li className="fix-bottom-menu">
              <div className={"list-group-item gap-2 "}>
                <Link to={"/"} className={"d-flex gap-2 menu "}>
                  <span>
                    <Setting />
                  </span>
                  <span className="theme_color">{open && "Setting"}</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="container mt-2 fix-content topNav">
        <nav className="navbar navbar-expand navbar-light  sticky-top rounded">
          <div className="container-fluid">
            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarSupportedContent"
            >
              <div className="d-none d-md-flex w-50">
                <div className="searchWrap ">
                  <Search />
                  <input
                    type="search"
                    className="form-control"
                    aria-label="Sizing example input "
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Search"
                  />
                </div>
              </div>

              <div className="d-flex d-md-none align-items-center">
                <div
                  className="theme_color me-2 cursor-pointer fs-3"
                  onClick={handleDrawer}
                >
                  <i className="bi bi-list "></i>
                </div>
                <div className="logo-mobile-wrapper">
                  <Logo />
                </div>
              </div>

              <div className="d-flex  align-items-center header_store_name gap-2">
                <div className="header_dropdown_bottom">
                  <select
                    className="form-select header_dropdown"
                    aria-label="Default select example"
                    onChange={(e) => updateStore(e)}
                    name="store"
                    id="id"
                    value={JSON.stringify(findStoreById())}
                  >
                    <option value="">Select Store</option>
                    {storeList?.map((item: any) => (
                      <option
                        key={item?.storeCode}
                        value={JSON.stringify(item)}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn p-0 dropdown-toggle d-flex align-items-center gap-1 border-0 outline-0"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="profile_name">
                      {user?.name?.charAt(0).toUpperCase()}
                    </div>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <NavLink to={Routers?.profile} className="dropdown-item">
                        Profile
                      </NavLink>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={Logout}
                      >
                        Log Out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className="container mt-3">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
