const CouponResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      ...val._source,
      status: val._source.itemStatus,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};

export { CouponResponse };

interface DiscountType {
  freeFirstDelivery: boolean;
}

interface Product {
  productCode: string;
  itemName: string;
}

interface Category {
  id: string;
  name: string;
}

interface FormValues {
  couponCode: string;
  description: string;
  startDate: any;
  endDate: any;
  discountType: DiscountType;
  categories: Category[];
  products: Product[];
  itemStatus: boolean;
  createdAt: string;
  updatedAt: string;
  extraField1: string;
  extraField2: string;
  extraField3: string;
  itemAction: string;
  entityName: string;
}

export type { FormValues };
