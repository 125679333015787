import { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Routers } from "../../Routes/Routes";
import country from "../../Data/Countries.json";
import { useAlert } from "../ContextAPI/ContextApi";
import { updateUser } from "../../Api/User/mutations";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { useFormik } from "formik";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
import Toast from "../Toast/Toast";
import { searchWithPKSK } from "../../Api/Search/mutation";
import FormEffect from "../../Effects/FormEffect";
import countryData from "../../Data/CountryCode.json";
import { ProfileUpdateSchema } from "./ValidationScheme";
import LoadingOverlay from "../../Effects/LoadingOverlay";

const UpdateManager = () => {
  const { storeId } = useStoreId();
  const [progress, setProgress] = useState(false);
  //   const [showPassword, setShowPassword] = useState(false);
  //   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { setAlert, setMessage, setErrorType } = useAlert();
  const { alert, message, errorType } = useAlert();
  const { PK, SK } = useParams();
  const [dataLoader, setdataLoader] = useState(false);
  //   const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      PK: "",
      SK: "",
      group: "",
      email: "",
      fullName: "",
      username: "",
      password: "",
      confirmPassword: "",
      phone: "+27",
      entityName: "",
      createdDate: "",
      isDeleted: false,
      isOnline: false,
      storeId: storeId,
      country_code: "",
      id: "",
    },

    validationSchema: ProfileUpdateSchema,
    onSubmit: async (values) => {
      if (storeId === "") {
        toast.error("First select a store");
        return;
      }
      setProgress(true);

      const CreatePreSignUpUserInput = {
        PK: values?.PK,
        SK: values?.SK,
        group: values?.group,
        email: values?.email,
        fullName: values?.fullName,
        username: values?.username,
        phone: values?.phone,
        entityName: values?.entityName,
        createdDate: values?.createdDate,
        isDeleted: values?.isDeleted,
        isOnline: values?.isOnline,
        storeId: values?.storeId,
        id: values?.id,
      };
      updateUser(PK, SK, CreatePreSignUpUserInput)
        .then(async (res: any) => {
          const result = JSON.parse(res?.data?.updateUser);
          const errorMessage = JSON.parse(result.body).error;
          if (result?.statusCode === 200) {
            navigate(Routers?.manager);
            setProgress(false);
            setAlert(true);
            setErrorType("success");
            setMessage("Manager Update Successfully");
          } else {
            setAlert(true);
            setErrorType("error");
            setMessage(errorMessage);
            setProgress(false);
          }
        })
        .catch((error: any) => {
          setAlert(true);
          setErrorType("error");
          setProgress(false);
          setMessage(error?.errors[0].message);
        });
    },
  });

  //   const togglePasswordVisibility = () => {
  //     setShowPassword(!showPassword);
  //   };

  //   const toggleConfirmPasswordVisibility = () => {
  //     setShowConfirmPassword(!showConfirmPassword);
  //   };

  const getUser = useCallback(async () => {
    setdataLoader(true);
    try {
      const res = await searchWithPKSK("user", PK, SK);
      setdataLoader(false);

      const userData = res?.hits?.[0]?._source || {};
      let countryCode = "";
      let phoneNumber = userData?.phone || "";
      let matchedCountry: any = null;
      const countryCodeWithoutPlus = userData?.phone?.replace(/^\+/, "");
      countryData.forEach((country) => {
        if (countryCodeWithoutPlus?.startsWith(country.phone)) {
          if (
            !matchedCountry ||
            country.phone.length > matchedCountry.phone.length
          ) {
            matchedCountry = country;
            countryCode = `+${country.phone}`;
            phoneNumber = countryCodeWithoutPlus.replace(country.phone, "");
          }
        }
      });
      if (matchedCountry) {
        const phoneLength = Array.isArray(matchedCountry.phoneLength)
          ? matchedCountry.phoneLength[0]
          : matchedCountry.phoneLength;
        if (phoneNumber.length !== phoneLength) {
          phoneNumber = "";
        }
      }
      const CreatePreSignUpUserInput = {
        ...userData,
        phone: userData?.phone.replace(countryCode, ""),
        country_code: countryCode,
        confirmPassword: userData.password,
      };

      formik.setValues(CreatePreSignUpUserInput);
    } catch (error) {
      setdataLoader(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PK, SK]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  //   const onUpdatePassword = async () => {
  //     if (formik?.values?.password !== formik?.values?.confirmPassword) {
  //       toast.error("Passwords do not match");
  //       return;
  //     }
  //     setIsLoading(true);
  //     const UserActionInput = {
  //       action: "changePassword",
  //       username: formik?.values?.email,
  //       newPassword: formik?.values?.password,
  //     };
  //     await updatePassword(UserActionInput)
  //       .then((response: any) => {
  //         const parsedResponse = JSON.parse(response?.data.changePasswordUser);
  //         const parsedBody = JSON.parse(parsedResponse.body);
  //         if (parsedResponse.statusCode === 200) {
  //           toast.success(parsedBody.message);
  //           setIsLoading(false);
  //         } else {
  //           toast.error(parsedBody.message);
  //           setIsLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //       });
  //   };

  return (
    <div className="w-100">
      <ToastContainer />
      {alert && message && errorType && (
        <Toast type={errorType} message={message} />
      )}

      <LoadingOverlay isVisible={progress} message="Please wait..." />

      <div className="form-top-sticky d-flex align-items-center justify-content-between gap-2">
        <div className="heading-title mt-2 mt-md-0">
          <h4 className="theme_color fw-bold">
            <Link
              className="text-decoration-none theme_color"
              to={Routers?.manager}
            >
              <i className="bi bi-chevron-left me-3"></i>
            </Link>
            Update A Manager
          </h4>
        </div>
      </div>
      {dataLoader ? (
        <FormEffect />
      ) : (
        <div className="container">
          <div className="container">
            <div className="border border-grey p-3 rounded mt-3 mt-md-4 white_bg">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      name="fullName"
                      onChange={formik.handleChange}
                      value={formik.values.fullName}
                      required
                    />
                    {formik.touched.fullName && formik.errors.fullName ? (
                      <div className="text-danger">
                        {formik.errors.fullName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      required
                      readOnly
                    />
                    {formik.touched.username && formik.errors.username ? (
                      <div className="text-danger">
                        {formik.errors.username}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Contact<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <select
                        className="form-select country-code-select"
                        id="country-code"
                        name="country_code"
                        onChange={formik.handleChange}
                        value={formik.values.country_code}
                      >
                        {country?.map((item) => (
                          <option
                            value={`+${item?.phone_code}`}
                            key={item.phone_code}
                          >
                            {`+${item?.phone_code}`}
                          </option>
                        ))}
                      </select>
                      <input
                        type="number"
                        className="form-control"
                        id="phone"
                        name="phone"
                        onChange={formik.handleChange}
                        value={formik?.values?.phone}
                        required
                      />
                    </div>
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="text-danger">{formik.errors.phone}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-2">
                <Link
                  className="text-decoration-none theme_color"
                  to={Routers?.manager}
                >
                  <button className="btn btn-outline-danger" type="button">
                    Cancel
                  </button>
                </Link>
                <button
                  className="btn btn-outline-success"
                  type="button"
                  onClick={() => formik.handleSubmit()}
                >
                  Update Manager
                </button>
              </div>
            </div>

            {/* <div className="border border-grey p-3 rounded mt-3 mt-md-4 white_bg">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        name="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        required
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="text-danger">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm Password<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        required
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <div className="text-danger">
                        {formik.errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end gap-2">
                <Link
                  className="text-decoration-none theme_color"
                  to={Routers?.manager}
                >
                  <button className="btn btn-outline-danger" type="button">
                    Cancel
                  </button>
                </Link>
                <button
                  className="btn btn-outline-success"
                  type="button"
                  onClick={() => {
                    onUpdatePassword();
                  }}
                >
                  {isLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    " Update Password"
                  )}
                </button>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateManager;
