import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Routers } from "../../Routes/Routes";
import { useAlert } from "../ContextAPI/ContextApi";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { formattedDate, generateUUID } from "../../Common Functions/Function";
import { addMembership } from "../../Api/Membership/mutation";
import { useState } from "react";
import { toast } from "react-toastify";
import Toast from "../Toast/Toast";
import LoadingOverlay from "../../Effects/LoadingOverlay";

interface FormValues {
  name: string;
  rewardsPointsPerRand: number;
  rewardsRandsPerPoint: number;
  rewardsPointsExpirationDays: number;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  rewardsPointsPerRand: Yup.number()
    .required("Points per Rand are required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
  rewardsRandsPerPoint: Yup.number()
    .required("Rands per Point are required")
    .positive("Must be a positive number"),
  rewardsPointsExpirationDays: Yup.number()
    .required("Expiration days are required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
});

const NewMemberShip = () => {
  const { alert, message, errorType, setAlert, setMessage, setErrorType } =
    useAlert();
  const { storeId } = useStoreId();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let index = process.env.REACT_APP_STACK
    ? `store${process.env.REACT_APP_STACK}`
    : "store";
  interface FormData {
    [key: string]: any;
  }
  const generateUniqueName = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Month with leading zero
    const day = now.getDate().toString().padStart(2, "0"); // Day with leading zero
    const hour = now.getHours().toString().padStart(2, "0"); // Hour with leading zero
    const minute = now.getMinutes().toString().padStart(2, "0"); // Minute with leading zero
    const second = now.getSeconds().toString().padStart(2, "0"); // Second with leading zero
    const random = Math.random().toString(36).substring(2, 6).toUpperCase(); // Random 4-character string

    // Format: YYMMDDHHMMSS + Random 4 characters
    return `${year}${month}${day}${hour}${minute}${second}${random}`;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: generateUniqueName(),
      rewardsPointsPerRand: 0,
      rewardsRandsPerPoint: 0,
      rewardsPointsExpirationDays: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const UUID = generateUUID();

      try {
        const newData: FormData = {
          PK: `MEMBERSHIP`,
          SK: `STOREMEMBERSHIP`,
          storeCode: UUID,
          id: "STOREMEMBERSHIP",
          entityName: index,
          createdDate: formattedDate(),
          isDeleted: `false`,
        };
        const mergeData = {
          ...values,
          ...newData,
        };
        await addMembership(mergeData)
          .then((res) => {
            const result = JSON.parse(res?.data?.createStore);
            const errorMessage = JSON.parse(result.body).message;
            if (result?.statusCode === 200) {
              navigate(Routers?.memberShip);
              setAlert(true);
              setErrorType("success");
              setMessage("MemberShip Added Successfully");
            } else {
              setErrorType("error");
              setAlert(true);
              setMessage(errorMessage);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            setAlert(true);
            setErrorType("error");
            setMessage(err?.errors[0].message);
          });
      } catch (error) {
        const { message } = error as Error;
        setIsLoading(false);
        toast.error(message);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleSubmit = () => {
    if (!storeId) {
      setAlert(true);
      setErrorType("error");
      setMessage("First select a store");
      return;
    }
    if (formik.isValid && storeId) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  return (
    <>
      {alert && message && (
        <Toast type={errorType ? errorType : "error"} message={message} />
      )}

      <LoadingOverlay isVisible={isLoading} message="Please wait..." />
      <form onSubmit={formik.handleSubmit}>
        <div className="form-top-sticky d-flex align-items-center justify-content-between gap-2">
          <h6 className="theme_color align-self-center h4 fw-bold mb-3">
            <Link
              className="text-decoration-none theme_color"
              to={Routers?.memberShip}
            >
              <i className="bi bi-chevron-left me-3"></i>
            </Link>
            {"Create New Membership"}
          </h6>
          <div className="row justify-content-between mb-4">
            <div className="col-auto d-flex align-items-center"></div>
            <div className="col-auto d-flex gap-2 justify-content-end">
              <Link className="text-decoration-none" to={Routers.memberShip}>
                <button className="btn btn-outline-danger" type="button">
                  Cancel
                </button>
              </Link>
              <button
                className="btn btn-outline-success"
                type="button"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {/* <div className="form-group col-12 mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </div> */}

        <div className="form-group col-12 mb-3">
          <label htmlFor="rewardsPointsPerRand" className="form-label">
            Rewards Points Per Rand
          </label>
          <input
            type="number"
            className="form-control"
            id="rewardsPointsPerRand"
            name="rewardsPointsPerRand"
            onChange={formik.handleChange}
            value={formik.values.rewardsPointsPerRand}
          />
          <small className="form-text text-muted">
            <i className="bi bi-info-circle me-1"></i>
            {`Number of points earned per ZAR ${formik.values.rewardsPointsPerRand} spent`}
          </small>
          {formik.touched.rewardsPointsPerRand &&
          formik.errors.rewardsPointsPerRand ? (
            <div className="text-danger">
              {formik.errors.rewardsPointsPerRand}
            </div>
          ) : null}
        </div>

        <div className="form-group col-12 mb-3">
          <label htmlFor="rewardsRandsPerPoint" className="form-label">
            Rands Per Point
          </label>
          <input
            type="number"
            id="rewardsRandsPerPoint"
            className="form-control"
            name="rewardsRandsPerPoint"
            onChange={formik.handleChange}
            value={formik.values.rewardsRandsPerPoint}
          />
          <small className="form-text text-muted">
            <i className="bi bi-info-circle me-1"></i>
            {`ZAR amount required to earn ${formik.values.rewardsRandsPerPoint} point`}
          </small>
          {formik.touched.rewardsRandsPerPoint &&
          formik.errors.rewardsRandsPerPoint ? (
            <div className="text-danger">
              {formik.errors.rewardsRandsPerPoint}
            </div>
          ) : null}
        </div>

        <div className="form-group col-12 mb-3">
          <label htmlFor="rewardsPointsExpirationDays" className="form-label">
            Points Expiration Days
          </label>
          <input
            type="number"
            className="form-control"
            id="rewardsPointsExpirationDays"
            name="rewardsPointsExpirationDays"
            onChange={formik.handleChange}
            value={formik.values.rewardsPointsExpirationDays}
          />
          {formik.touched.rewardsPointsExpirationDays &&
          formik.errors.rewardsPointsExpirationDays ? (
            <div className="text-danger">
              {formik.errors.rewardsPointsExpirationDays}
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default NewMemberShip;
